// eslint-disable-next-line import/prefer-default-export
export const milbLpHero = {
  title: "Upgrade your minor league stadium with a big-league, omnichannel POS",
  subtext:
    "Increase revenue, cut labor costs, and gain better data—while shortening lines and creating an unforgettable fan experience.",
  heroImg: "milb-lp-hero.jpg",
  imageClassName: "smaller-hero",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/milb/learn-more",
  },
  fatTitle: "",
};

export const milbLpTrusted = [
  {
    title: "30%",
    subtext: "more items per check with self-service kiosks (Frontier Field)",
  },
  {
    title: "15%",
    subtext: "increase in average check size (Globe Life Park)",
  },
  {
    title: "75%",
    subtext: "less time spent training cashiers (BB&T Ballpark)",
  },
  {
    title: "15%",
    subtext:
      "decrease in end-of-event closing reporting (Professional Sports Catering)",
  },
];

export const milbLpWhiteGlove = {
  mainTitle: "A partner you can trust",
  title: "Get world-class support",
  content:
    "Our team works around the clock to ensure your operations are always running smoothly. And we’re never content with just “good enough.” With 500+ product engineers and developers dedicated to building better tech, it’s no wonder that 63% of major league sports use SpotOn.  Operate your stadium with the peace-of-mind that we’ll always be there for you to create stellar fan experiences and sell better—no matter what the world throws at you.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/milb/learn-more",
  },
};

export const milbLpLargeFeatures = {
  sectionTitle:
    "Better tech to streamline stadium concessions, restaurants, and retail",
  featureBlocks: [
    {
      blockTitle: "Sell more while reducing lines for fans",
      blockList: [
        "Self-service kiosks",
        "Mobile & online ordering",
        "QR ordering",
        "Handhelds for hawkers",
        "KDS systems to boost kitchen efficiency",
      ],
      blockImg: "milb-lp-a.jpg",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/milb/learn-more",
      },
    },
    {
      blockTitle: "One cloud-based system to run sales across your operation",
      blockImg: "milb-lp-b.jpg",
      blockList: [
        "Intuitive cloud point-of-sale",
        "Food & beverage and retail solutions",
        "Suite management",
        "Payments with built-in offline mode",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/milb/learn-more",
      },
    },
    {
      blockTitle: "Enterprise management, reporting, and analytics",
      blockList: [
        "Real-time, cloud-based reporting",
        "Inventory tracking",
        "Cash management",
        "Hawking support",
        "Multi-location management",
      ],
      blockImg: "milb-lp-c.jpg",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/milb/learn-more",
      },
    },
  ],
};

export const milbLPTestimonial = {
  header: "What other minor league stadium operators are saying",
  title: "Be Our Guest Ltd., Sahlen Field, home of the Buffalo Bisons",
  name: "Rob Free",
  imgs: "rob-free.png",
  subtext:
    "“SpotOn made transitioning to a cloud-based POS seamless. In my 25 years of dealing with POS companies, I’ve never experienced this level of consistent customer service and support when it matters the most.”",
};

export const milbLpCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-enterprise-lp.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/milb/learn-more",
  },
};
