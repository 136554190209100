import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import { dynamicHeadlines } from "../../../utils/url-utils";
import Hero from "../../../components/Hero/hero";
import {
  milbLpHero,
  milbLpWhiteGlove,
  milbLpLargeFeatures,
  milbLpCTA,
  milbLpTrusted,
  milbLPTestimonial,
} from "../../../data/landing/milb";
import heroBg from "../../../images/hero-images/home-decor-hero.png";
import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";

// const NoVisualsCustom = loadable(() =>
//   import("../../../components/NoVisuals/NoVisualsCustom")
// );

const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));

const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnersEnerprise-stadiums")
);
const InlineQuote = loadable(() => import("../../../components/InlineQuote"));

const whiteGloveImport = "milb-white-glove.jpg";

const milbLp = () => {
  return (
    <Layout isLanding phoneNumber="+18775594225" phoneText="(877) 559-4225">
      <SEO
        title="Enterprise Point of Sale | SpotOn"
        description="Increase revenue, cut labor costs, and gain better data—while shortening lines and creating an unforgettable fan experience."
        image={`https://spoton.com/${heroBg}`}
      />
      <Hero
        sectionData={milbLpHero}
        // heroBg="retailLp-decor-hero.png"
        dynamicH1={dynamicHeadlines()}
        hideBanner
      />
      <section style={{ marginTop: "80px" }}>
        <TrustedNumbers numbersArray={milbLpTrusted} />
      </section>
      <section style={{ marginTop: "40px" }}>
        <InlineQuote sectionData={milbLPTestimonial} />
      </section>
      <section style={{ marginBottom: 80 }}>
        <PartnerLogos />
      </section>
      <LargeFeatures
        sectionData={milbLpLargeFeatures}
        style={{ marginTop: "128px !important" }}
      />
      <WhiteGlove
        sectionData={milbLpWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeCta sectionData={milbLpCTA} />
    </Layout>
  );
};

export default milbLp;
